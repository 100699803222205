import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import mintEventDispatcher from '../../actions/mintEventDispatcher';
import isServer from '../../utils/isServer';

export default function withMintEventDispatcher(DecoratedComponent) {
  class ComponentWithMintEventDispatcher extends Component {
    static propTypes = {
      mintEventDispatcher: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);

      // Setup the mint player event listeners here, but ONLY on the client.
      if (!isServer()) {
        this.removeListeners = props.mintEventDispatcher();
      }
    }

    componentWillUnmount() {
      if (this.removeListeners) {
        this.removeListeners();
      }
    }

    render() {
      return <DecoratedComponent {...this.props} />;
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      mintEventDispatcher: mintEventDispatcher(dispatch),
    };
  }

  return connect(null, mapDispatchToProps)(ComponentWithMintEventDispatcher);
}
