const actions = {
  show: 'show',
  hide: 'hide',
  yes: 'yes',
  dismiss: 'dismiss',
  no: 'no',
  click: 'click',
  prerollRequest: 'prerollRequest',
  midrollRequest: 'midrollRequest',
};

const labels = {
  gdpr: 'gdpr',
  gdprConsent: 'gdpr_consent',
  pause: 'pause',
  resume: 'resume',
  ccpaConsent: 'ccpa_consent',
  alwaysOnLogo: 'always_on_logo',
  response: 'response',
};

const ad = {
  category: 'ad',
  actions,
  labels,
};

export default ad;
